<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('menu')"
                        :isNewButton="checkPermission('menu_store')"
                        @new-button-click="add"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('menu')"
                              :isNewButton="checkPermission('menu_store')"
                              @new-button-click="add"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="modal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? "add" : "edit") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input v-model="form.name">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="rank" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('rank')">
                                                <b-form-input type="number" v-model="form.rank">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="class" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('class')">
                                                <b-form-input v-model="form.class">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="user_type" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('user_type')">
                                                <b-form-select v-model="form.user_type" :options="options"></b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t("save") | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import MenuService from "@/services/MenuService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t("menu"),
        };
    },
    data() {
        return {
            options: [
                {value: 1, text: "Staff"},
                {value: 2, text: "Student"},
            ],
            id: 0,
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "menu_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "menu_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                            {
                                text: this.$t("menu_items"),
                                class: "ri-list-check ",
                                permission: "menuitem_index",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.openMenu(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: false,
                    },
                    {
                        label: this.$t("name"),
                        field: "name",
                        sortable: true,
                    },
                    {
                        label: this.$t("class"),
                        field: ("class"),
                        sortable: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        openMenu() {
            if (this.id > 0) {
                this.$router.push("/menu/items/" + this.id);
            }
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return MenuService.getAll(config)
                              .then((response) => {
                                  this.datatable.rows = response.data.data;
                                  this.datatable.total = response.data.pagination.total;
                              })
                              .finally(() => {
                                  this.datatable.isLoading = false;
                              });
        },
        loadData() {
            if (this.id > 0) {
                MenuService.get(this.id)
                           .then((response) => {
                               this.$refs.storeForm.reset();
                               this.form = response.data.data;
                               this.$refs.modal.$refs.commonModal.show();
                           })
                           .catch((error) => {
                               if (error.data.message) {
                                   this.$toast.error(this.$t("api." + error.data.message));
                               }
                           });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            if (isValid) {
                if (this.id == 0) {
                    MenuService.store(this.form, this.value)
                               .then((response) => {
                                   this.$toast.success(this.$t("api." + response.data.message));
                               })
                               .catch((error) => {
                                   this.showErrors(error)
                               })
                }
                else {
                    MenuService.update(this.id, this.form)
                               .then((response) => {
                                   this.$toast.success(this.$t("api." + response.data.message));
                               })
                               .catch((error) => {
                                   this.showErrors(error)
                               })
                }
            }
        },
        showErrors(error) {
            if (error.status == 422) {

                if (error.data.errors.name) {
                    this.$refs.storeForm.errors.name.push(error.data.errors.name[0]);
                }
                if (error.data.errors.user_type) {
                    this.$refs.storeForm.errors.user_type.push(error.data.errors.user_type[0]);
                }

                if (error.data.errors.rank) {
                    this.$refs.storeForm.errors.rank.push(error.data.errors.rank[0]);
                }
                if (error.data.errors.class) {
                    this.$refs.storeForm.errors.class.push(error.data.errors.class[0]);
                }
            }
            else if (error.status == 406) {
                this.$toast.error(this.$t('api.' + error.data.message));
            }
        },
        delete(id) {
            this.deleteModal(() => {
                MenuService.deleteMenu(id)
                           .then((response) => {
                               this.filter();
                               this.$toast.success(this.$t("api." + response.data.message));
                           })
                           .catch((error) => {
                               this.$toast.error(this.$t("api." + error.data.message));
                           });
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        },
    },
};
</script>

